@font-face {
  font-family: 'Arcade';
  src: url(/static/media/arcade.f841b176.ttf);
}

body {
  margin: 0;
  padding: 0;
  font-family: "Arcade";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.App {
  text-align: center;
}

.App-logo {
  height: 160px;
  width: 160px;
  border-radius: 50%;
  object-fit: cover;
}

.App-img {
  background-color: white;
  height: 40px;
  width: 40px;
  display: inline-block;
  margin: 20px;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-text {
  background: white;
  width: calc(100% - 40px);
  max-width: 600px;
  margin-bottom: 0;
  font-size: 26px;
  margin-top: 20px;
  box-sizing: border-box;
}

h1.App-text {
  font-size: 50px;
}
