@font-face {
  font-family: 'Arcade';
  src: url(./assets/fonts/arcade.ttf);
}

body {
  margin: 0;
  padding: 0;
  font-family: "Arcade";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}